* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* You can also add other default styles */
html, body {
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
  background-color: #061437;
}

#company-profile {
  padding-top: 10vh; /* Adjust this value if necessary */
  margin-top: -10vh; /* Negative margin to offset the padding */
}

#our-philosophy {
  padding-top: 16vh; /* Adjust this value if necessary */
  margin-top: -16vh; /* Negative margin to offset the padding */
}

#projects-overview {
  padding-top: 17vh; /* Adjust this value if necessary */
  margin-top: -17vh; /* Negative margin to offset the padding */
}

#contact-us-app {
  margin-bottom: 15vh;
}
