@font-face {
    font-family: 'HindMadurai-Bold';
    src: url('../Fonts/Hind_Madurai/HindMadurai-Bold.ttf') format('truetype');
}

/* Font Faces */
@font-face {
    font-family: 'Poppins-Bold';
    src: url('../Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

/* Keyframes for slide-in animation */
@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Navbar Styles */
.navbar {
    --navbar-height: 10vh; /* Define a CSS variable for the navbar height */
    min-height: var(--navbar-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #061437;
    padding: 0 5rem;
    box-sizing: border-box; /* Ensure padding is included in the width */
    position: fixed; /* Change to fixed */
    top: 0; /* Position at the top */
    left: 0; /* Position at the left */
    width: 100%; /* Full width */
    z-index: 1000; /* Ensure the navbar is on top */
}

/* Logo Styles */
.navbar-logo {
    flex: 1;
    display: flex;
    align-items: center; /* Align items vertically */
    margin-left: 1rem;
    animation: slideInFromLeft 0.75s ease-out forwards;
    margin-right: 1.5rem;
}

.navbar-logo img {
    height: 5rem;
}

.navbar-logo .navbar-text {
    color: #F4E282;
    font-size: 1.2rem;
    font-family: 'Poppins-Regular', sans-serif;
    margin-left: 1rem; /* Space between logo and text */
    white-space: nowrap; /* Ensure text stays in one line */
}

.navbar-logo img {
    height: 5rem;
}

.navbar-text-link {
    text-decoration: none; /* Remove the underline */
}

/* Links Styles */
.navbar-links {
    display: flex;
    gap: 4rem;
    flex-grow: 2;
    justify-content: center;
    animation: slideInFromLeft 1.5s ease-out forwards;
}

.navbar-links .nav-link {
    position: relative;
    color: #F4E282;
    text-decoration: none;
    font-size: 1.2rem;
    font-family: 'Poppins-Regular', sans-serif;
    cursor: pointer;
    white-space: nowrap;
}

.navbar-links a {
    position: relative;
    color: #F4E282;
    text-decoration: none;
    font-size: 1.2rem;
    font-family: 'Poppins-Regular', sans-serif;
    overflow: hidden;
    white-space: nowrap;
}

.navbar-links a::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: -100%;
    background-color: #F4E282;
    transition: left 0.3s ease;
}

.navbar-links a:hover::after {
    left: 0;
}

/* Dropdown Styles */
.navbar-links .nav-link .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #061437;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 230px !important; /* Force the width */
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    z-index: 1000;
}

.navbar-links .nav-link .dropdown.show {
    max-height: 500px; /* Adjust this value based on content */
    opacity: 1;
}

.navbar-links .nav-link .dropdown a {
    position: relative;
    color: #F4E282;
    text-decoration: none;
    font-size: 1.2rem;
    font-family: 'Poppins-Regular', sans-serif;
    text-align: left;
    overflow: hidden;
    display: inline-block;
}

/* Icon Rotation */
.navbar-links .nav-link .fa-angle-down,
.navbar-links .nav-link .fa-angle-up {
    transition: transform 0.3s ease;
}

.navbar-links .nav-link .rotate {
    transform: rotate(180deg);
}

/* Right Section Styles */
.navbar-right {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 1rem;
    justify-content: flex-end; /* Ensure the right section is aligned to the end */
    animation: slideInFromLeft 2.25s ease-out forwards;
}

/* Contact Button Styles */
.contact-button {
    position: relative;
    background-color: #061437;
    color: #F4E282;
    border: none;
    padding: 0.5rem 2.5rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: 'Poppins-Regular', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    transition: color 0.3s ease;
}

.contact-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #F4E282;
    transition: width 0.3s ease;
    z-index: 0;
}

.contact-button:hover::before {
    width: 100%;
}

.contact-button:hover {
    color: #061437;
}

.contact-button span {
    position: relative;
    z-index: 1;
}

/* Social Icons Styles */
.social-icons {
    display: flex;
    gap: 0.5rem;
    flex-shrink: 0;
    flex-direction: row; /* Ensure icons are in a row */
}

.social-icons svg {
    color: #F4E282;
    font-size: 1.6rem;
    cursor: pointer;
}

/* Hamburger Icon Styles */
.menu-icon {
    display: none;
    position: relative;
    width: 40px; /* Increased width */
    height: 32px; /* Increased height */
    cursor: pointer;
    animation: slideInFromLeft 1s ease-out forwards;
}

.menu-icon span {
    display: block;
    position: absolute;
    height: 5px; /* Increased height */
    width: 100%;
    background: #F4E282;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.25s ease-in-out;
}

.menu-icon span:nth-child(1) {
    top: 0px;
}

.menu-icon span:nth-child(2) {
    top: 12px; /* Adjusted top position */
}

.menu-icon span:nth-child(3) {
    top: 24px; /* Adjusted top position */
}

.menu-icon.open span:nth-child(1) {
    top: 12px; /* Adjusted top position */
    transform: rotate(135deg);
}

.menu-icon.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.menu-icon.open span:nth-child(3) {
    top: 12px; /* Adjusted top position */
    transform: rotate(-135deg);
}

/* Mobile Styles */

/* CSS for tablets and small laptops */
@media only screen and (max-width: 1024px) {
    /* Navbar container */
    .navbar {
        padding: 0 1rem; /* Adjust padding for smaller screens */
    }

    /* Navbar logo */
    .navbar-logo {
        margin-top: 1rem; /* Add top margin */
        margin-bottom: 1rem; /* Add bottom margin */
    }

    /* Navbar links container */
    .navbar-links {
        flex-direction: column;
        gap: 1rem;
        position: fixed; /* Fixed positioning for consistent placement */
        top: 0; /* Start from the top of the viewport */
        left: 0;
        width: 100%;
        height: 100%; /* Cover the entire viewport */
        background: #061437;
        padding: 2rem 1rem;
        justify-content: center;
        align-items: center;
        z-index: 998; /* Ensure the dropdown appears below the menu icon */
        opacity: 0; /* Start with opacity 0 */
        visibility: hidden; /* Start with visibility hidden */
        max-height: 0; /* Start with max-height 0 */
        overflow: hidden; /* Hide overflow */
        transition: opacity 0.3s ease, visibility 0.3s ease, max-height 0.3s ease; /* Add transition for opacity, visibility, and max-height */
    }

    /* Navbar links when mobile menu is open */
    .navbar-links.show {
        opacity: 1; /* Set opacity to 1 when shown */
        visibility: visible; /* Set visibility to visible when shown */
        max-height: 100vh; /* Set max-height to 100vh when shown */
    }

    /* Hide navbar-right on mobile */
    .navbar-right {
        display: none;
    }

    /* Contact button styles */
    .contact-button {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: #F4E282;
        color: #061437;
        font-size: 1.7rem;
        opacity: 0; /* Start with opacity 0 */
        transition: opacity 0.3s ease; /* Add transition for opacity */
    }

    /* Show contact button when mobile menu is open */
    .navbar-links.show .contact-button {
        opacity: 1; /* Set opacity to 1 when shown */
    }

    /* Menu icon styles */
    .menu-icon {
        display: block;
        z-index: 999; /* Ensure the menu icon is above the navbar-links */
    }

    /* Social icons container */
    .social-icons {
        flex-direction: row; /* Ensure icons are in a row on mobile */
        justify-content: center; /* Center the icons */
        width: 100%; /* Ensure the container takes full width */
        flex-wrap: nowrap; /* Prevent wrapping */
        font-size: 2rem; /* Increase font size of social icons */
        opacity: 0; /* Start with opacity 0 */
        transition: opacity 0.3s ease; /* Add transition for opacity */
    }

    /* Show social icons when mobile menu is open */
    .navbar-links.show .social-icons {
        opacity: 1; /* Set opacity to 1 when shown */
    }

    /* Increase size of social icons when navbar-links.show is active */
    .navbar-links.show .social-icons svg {
        font-size: 2rem; /* Increase font size of social icons */
    }

    /* Increase font size for main links */
    .navbar-links .nav-link,
    .navbar-links > a {
        font-size: 1.7rem; /* Increase font size */
    }

    /* Increase font size for dropdown links */
    .navbar-links.show .nav-link .dropdown a {
        font-size: 1.3rem; /* Increase font size for dropdown links */
    }

    /* Dropdown styles for mobile */
    .navbar-links.show .nav-link .dropdown {
        height: 0; /* Start with height 0 */
        pointer-events: none; /* Disable pointer events */
    }

    /* Dropdown styles when shown */
    .navbar-links.show .nav-link .dropdown.show {
        height: auto; /* Reset height */
        pointer-events: auto; /* Enable pointer events */
    }
}

/* Small Devices (Larger Phones/Small Tablets) */
@media only screen and (max-width: 767px) {
    /* Navbar container */
    .navbar {
        padding: 0 1rem; /* Adjust padding for smaller screens */
    }

    /* Navbar logo */
    .navbar-logo {
        margin-top: 1rem; /* Add top margin */
        margin-bottom: 1rem; /* Add bottom margin */
    }

    /* Navbar links container */
    .navbar-links {
        flex-direction: column;
        gap: 1rem;
        position: fixed; /* Fixed positioning for consistent placement */
        top: 0; /* Start from the top of the viewport */
        left: 0;
        width: 100%;
        height: 100%; /* Cover the entire viewport */
        background: #061437;
        padding: 2rem 1rem;
        justify-content: center;
        align-items: center;
        z-index: 998; /* Ensure the dropdown appears below the menu icon */
        opacity: 0; /* Start with opacity 0 */
        visibility: hidden; /* Start with visibility hidden */
        max-height: 0; /* Start with max-height 0 */
        overflow: hidden; /* Hide overflow */
        transition: opacity 0.3s ease, visibility 0.3s ease, max-height 0.3s ease; /* Add transition for opacity, visibility, and max-height */
    }

    /* Navbar links when mobile menu is open */
    .navbar-links.show {
        opacity: 1; /* Set opacity to 1 when shown */
        visibility: visible; /* Set visibility to visible when shown */
        max-height: 100vh; /* Set max-height to 100vh when shown */
    }

    /* Hide navbar-right on mobile */
    .navbar-right {
        display: none;
    }

    /* Contact button styles */
    .contact-button {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: #F4E282;
        color: #061437;
        font-size: 1.7rem;
        opacity: 0; /* Start with opacity 0 */
        transition: opacity 0.3s ease; /* Add transition for opacity */
    }

    /* Show contact button when mobile menu is open */
    .navbar-links.show .contact-button {
        opacity: 1; /* Set opacity to 1 when shown */
    }

    /* Menu icon styles */
    .menu-icon {
        display: block;
        z-index: 999; /* Ensure the menu icon is above the navbar-links */
    }

    /* Social icons container */
    .social-icons {
        flex-direction: row; /* Ensure icons are in a row on mobile */
        justify-content: center; /* Center the icons */
        width: 100%; /* Ensure the container takes full width */
        flex-wrap: nowrap; /* Prevent wrapping */
        font-size: 2rem; /* Increase font size of social icons */
        opacity: 0; /* Start with opacity 0 */
        transition: opacity 0.3s ease; /* Add transition for opacity */
    }

    /* Show social icons when mobile menu is open */
    .navbar-links.show .social-icons {
        opacity: 1; /* Set opacity to 1 when shown */
    }

    /* Increase size of social icons when navbar-links.show is active */
    .navbar-links.show .social-icons svg {
        font-size: 2rem; /* Increase font size of social icons */
    }

    /* Increase font size for main links */
    .navbar-links .nav-link,
    .navbar-links > a {
        font-size: 1.7rem; /* Increase font size */
    }

    /* Increase font size for dropdown links */
    .navbar-links.show .nav-link .dropdown a {
        font-size: 1.3rem; /* Increase font size for dropdown links */
    }

    /* Dropdown styles for mobile */
    .navbar-links.show .nav-link .dropdown {
        height: 0; /* Start with height 0 */
        pointer-events: none; /* Disable pointer events */
    }

    /* Dropdown styles when shown */
    .navbar-links.show .nav-link .dropdown.show {
        height: auto; /* Reset height */
        pointer-events: auto; /* Enable pointer events */
    }
}

@media only screen and (max-width: 480px) {
    /* Navbar container */
    .navbar {
        padding: 0 1rem; /* Adjust padding for smaller screens */
    }

    /* Navbar logo */
    .navbar-logo {
        margin-top: 1rem; /* Add top margin */
        margin-bottom: 1rem; /* Add bottom margin */
    }

    /* Navbar links container */
    .navbar-links {
        flex-direction: column;
        gap: 1rem;
        position: fixed; /* Fixed positioning for consistent placement */
        top: 0; /* Start from the top of the viewport */
        left: 0;
        width: 100%;
        height: 100%; /* Cover the entire viewport */
        background: #061437;
        padding: 2rem 1rem;
        justify-content: center;
        align-items: center;
        z-index: 998; /* Ensure the dropdown appears below the menu icon */
        opacity: 0; /* Start with opacity 0 */
        visibility: hidden; /* Start with visibility hidden */
        max-height: 0; /* Start with max-height 0 */
        overflow: hidden; /* Hide overflow */
        transition: opacity 0.3s ease, visibility 0.3s ease, max-height 0.3s ease; /* Add transition for opacity, visibility, and max-height */
    }

    /* Navbar links when mobile menu is open */
    .navbar-links.show {
        opacity: 1; /* Set opacity to 1 when shown */
        visibility: visible; /* Set visibility to visible when shown */
        max-height: 100vh; /* Set max-height to 100vh when shown */
    }

    /* Hide navbar-right on mobile */
    .navbar-right {
        display: none;
    }

    /* Contact button styles */
    .contact-button {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: #F4E282;
        color: #0C1E4C;
        font-size: 1.5rem;
        opacity: 0; /* Start with opacity 0 */
        transition: opacity 0.3s ease; /* Add transition for opacity */
    }

    /* Show contact button when mobile menu is open */
    .navbar-links.show .contact-button {
        opacity: 1; /* Set opacity to 1 when shown */
    }

    /* Menu icon styles */
    .menu-icon {
        display: block;
        z-index: 999; /* Ensure the menu icon is above the navbar-links */
    }

    /* Social icons container */
    .social-icons {
        flex-direction: row; /* Ensure icons are in a row on mobile */
        justify-content: center; /* Center the icons */
        width: 100%; /* Ensure the container takes full width */
        flex-wrap: nowrap; /* Prevent wrapping */
        font-size: 2rem; /* Increase font size of social icons */
        opacity: 0; /* Start with opacity 0 */
        transition: opacity 0.3s ease; /* Add transition for opacity */
    }

    /* Show social icons when mobile menu is open */
    .navbar-links.show .social-icons {
        opacity: 1; /* Set opacity to 1 when shown */
    }

    /* Increase size of social icons when navbar-links.show is active */
    .navbar-links.show .social-icons svg {
        font-size: 2rem; /* Increase font size of social icons */
    }

    /* Increase font size for main links */
    .navbar-links .nav-link,
    .navbar-links > a {
        font-size: 1.5rem; /* Increase font size */
    }

    /* Increase font size for dropdown links */
    .navbar-links.show .nav-link .dropdown a {
        font-size: 1.2rem; /* Increase font size for dropdown links */
    }

    /* Dropdown styles for mobile */
    .navbar-links.show .nav-link .dropdown {
        height: 0; /* Start with height 0 */
        pointer-events: none; /* Disable pointer events */
    }

    /* Dropdown styles when shown */
    .navbar-links.show .nav-link .dropdown.show {
        height: auto; /* Reset height */
        pointer-events: auto; /* Enable pointer events */
    }
}