.footer {
    width: 100%;
    background-color: #061437;
}

.footer-complete {
    width: 80%;
    display: flex;
    justify-content: space-between;
    background-color: #061437; /* Example background color */
    margin: 0 auto; /* Center the footer horizontally */
}

.footer-left {
    flex: 1;
    display: flex;
    align-items: center;
}

.footer-logo {
    max-width: 100%;
    height: auto;
}

.footer-right {
    flex: 2;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}

.footer-column {
    flex: 1;
    padding: 0 10px;
    text-align: left;
    font-family: 'Poppins-Light', sans-serif;
}

.footer-column h3 {
    margin-bottom: 10px;
    color: #F4E282;
    font-size: 1.2rem;
}

.footer-column ul {
    list-style: none;
    padding: 0;
}

/* Remove the default text decoration from the Link component */
.footer-column a {
    text-decoration: none;
    color: inherit; /* Inherit color from parent element */
}

/* Ensure the li element styles are applied to the Link component */
.footer-column li {
    margin-bottom: 5px;
    color: #ffffff;
    transition: color 0.3s ease; /* Add transition for smooth color change */
    cursor: pointer;
}

.footer-column li:hover {
    color: #F4E282; /* Change color on hover */
}

.footer-social-icons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.footer-social-icons .fa-facebook,
.footer-social-icons .fa-x-twitter,
.footer-social-icons .fa-instagram,
.footer-social-icons .fa-linkedin {
    color: #ffffff;
    font-size: 1.5rem;
}

@media only screen and (max-width: 1024px) {
    .footer-complete {
        flex-direction: column;
        align-items: center;
    }

    .footer-left, .footer-right {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
    }

    .footer-left {
        justify-content: center;
    }

    .footer-right {
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        width: 100%;
        margin-bottom: 1rem;
        text-align: center;
    }

    .footer-social-icons {
        justify-content: center;
    }

    .footer-logo {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 767px) {
    .footer-complete {
        flex-direction: column;
        align-items: center;
    }

    .footer-left, .footer-right {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
    }

    .footer-left {
        justify-content: center;
    }

    .footer-right {
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        width: 100%;
        margin-bottom: 1rem;
        text-align: center;
    }

    .footer-social-icons {
        justify-content: center;
    }

    .footer-logo {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 480px) {
    .footer-complete {
        flex-direction: column;
        align-items: center;
    }

    .footer-left, .footer-right {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
    }

    .footer-right {
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        width: 100%;
        margin-bottom: 1rem;
        text-align: center;
    }

    .footer-social-icons {
        justify-content: center;
    }
}