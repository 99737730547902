@font-face {
    font-family: 'Poppins-Bold';
    src: url('../Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('../Fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'HindMadurai-Bold';
    src: url('../Fonts/Hind_Madurai/HindMadurai-Bold.ttf') format('truetype');
}

.contact-us {
    width: 100%;
    height: 90vh;
    background-color: #061437;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-title {
    margin-top: 2vh;
    text-align: center;
    font-family: 'Poppins-Regular', sans-serif;
    color: #FFFFFF;
    font-size: 3rem;
    font-weight: normal;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-top: 5vh;
}

.form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3vh;
}

.input-container {
    position: relative;
    width: 49%;
}

.form-input {
    width: 100%;
    padding: 1.5vh;
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1.3rem;
    background-color: #061437; /* Set background color */
    border: none; /* Remove default border */
    border-bottom: 2px solid #FFFFFF; /* Add white bottom border */
    color: #FFFFFF; /* Set text color */
    outline: none; /* Remove default focus outline */
}

.form-input:-webkit-autofill {
    background-color: #061437; /* Set background color */
    border-bottom: 2px solid #FFFFFF; /* Add white bottom border */
    -webkit-text-fill-color: #FFFFFF; /* Set text color to white */
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1.3rem;
    outline: none; /* Remove default focus outline */
    transition: background-color 5000s ease-in-out 0s; /* Prevent background color change */
}

.form-input:focus + .form-label,
.form-input:not(:placeholder-shown) + .form-label {
    transform: translateY(-2.5rem);
    font-size: 0.8rem;
    color: #FFFFFF;
}

.form-label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1.3rem;
    color: #FFFFFF;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

.form-textarea {
    width: 100%;
    padding: 1vh;
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1.3rem;
    height: 23vh;
    border-radius: 20px;
    background-color: #FFFFFF; /* Set background color to white */
    border: none; /* Remove default border */
    color: #061437; /* Set text color */
    outline: none; /* Remove default focus outline */
}

.form-textarea:-webkit-autofill {
    background-color: #FFFFFF; /* Set background color to white */
    -webkit-text-fill-color: #061437; /* Set text color to dark blue */
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1.3rem;
    outline: none; /* Remove default focus outline */
    transition: background-color 5000s ease-in-out 0s; /* Prevent background color change */
}

.form-row-textarea {
    margin-top: 3vh; /* Add gap above the textarea row */
}

.form-row-submit {
    justify-content: center; /* Center the submit button row */
}

.form-submit {
    padding: 1vh 8vh;
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 1.5rem;
    background-color: #FFFFFF;
    color: #061437;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add transition for smooth effect */
}

.form-submit:hover {
    background-color: #061437; /* Change background color on hover */
    color: #FFFFFF; /* Change text color on hover */
}

/* Placeholder color */
.form-input::placeholder {
    color: #FFFFFF; 
}

.form-textarea::placeholder {
    color: #061437;
}

.contact-info {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 5vh;
    color: #FFFFFF;
    font-family: 'Poppins-Light', sans-serif;
    border-bottom: 2px solid #FFFFFF; /* Add white bottom border */
}

.contact-info-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-info-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-info-item {
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
}

.contact-icon {
    margin-right: 1vh;
    font-size: 1.5rem;
}

.contact-text {
    font-size: 1.3rem;
}

.no-link-style {
    color: inherit; /* Inherit the color from the parent element */
    text-decoration: none; /* Remove underline */
}

@media only screen and (max-width: 1280px) {
    .form-container {
        width: 90%; /* Adjust the width to fit smaller screens */
    }
}

@media only screen and (max-width: 1024px) {

    .contact-us {
        height: 90vh;
    }

    .contact-info {
        flex-direction: column; /* Stack contact info vertically */
        align-items: center; /* Center align contact info */
        width: 80%; /* Adjust contact info width for tablets */
        margin-top: 3vh; /* Adjust margin above contact info */
    }

    .contact-info-left,
    .contact-info-right {
        width: 100%; /* Full width for contact info sections */
        justify-content: center; /* Center align contact info sections */
        margin-bottom: 2vh; /* Add margin between contact info sections */
    }

    .contact-info-item {
        justify-content: center; /* Center align contact info items */
    }

    .contact-icon {
        margin-right: 0.5vh; /* Adjust margin for contact icons */
        font-size: 1.2rem; /* Adjust font size for contact icons */
    }

    .contact-text {
        font-size: 1rem; /* Adjust font size for contact text */
    }
}

@media only screen and (max-width: 1016px) {
    .contact-info {
        flex-direction: column; /* Stack contact info vertically */
        align-items: center; /* Center align contact info */
        width: 80%; /* Adjust contact info width for tablets */
        margin-top: 3vh; /* Adjust margin above contact info */
    }

    .contact-info-left,
    .contact-info-right {
        width: 100%; /* Full width for contact info sections */
        justify-content: center; /* Center align contact info sections */
        margin-bottom: 2vh; /* Add margin between contact info sections */
    }

    .contact-info-item {
        justify-content: center; /* Center align contact info items */
    }

    .contact-icon {
        margin-right: 0.5vh; /* Adjust margin for contact icons */
        font-size: 1.2rem; /* Adjust font size for contact icons */
    }

    .contact-text {
        font-size: 1rem; /* Adjust font size for contact text */
    }
}

@media only screen and (max-width: 767px) {

    .contact-us {
        height: auto;
    }

    .contact-title {
        font-size: 2.8rem;
    }

    .form-container {
        width: 80%; /* Adjust the width to fit smaller screens */
    }

    .form-label {
        font-size: 1.3rem;
    }

    .form-input {
        font-size: 1.3rem;
    }

    .form-row {
        flex-direction: column; /* Stack each input on top of each other */
        align-items: center; /* Center align the inputs */
    }

    .input-container {
        width: 100%; /* Make each input container take full width */
        margin-bottom: 2vh; /* Add some space between inputs */
    }

    .form-textarea {
        height: 18vh; /* Adjust the height of the textarea */
        font-size: 1.3rem;
    }

    .form-submit {
        width: 80%; /* Make the submit button take full width */
        padding: 1vh 0; /* Adjust padding for smaller screens */
        font-size: 1.4rem;
    }

    .contact-info {
        flex-direction: column; /* Stack contact info vertically */
        align-items: center; /* Center align contact info */
        width: 80%; /* Adjust contact info width for tablets */
        margin-top: 3vh; /* Adjust margin above contact info */
    }

    .contact-info-left,
    .contact-info-right {
        width: 100%; /* Full width for contact info sections */
        justify-content: center; /* Center align contact info sections */
        margin-bottom: 2vh; /* Add margin between contact info sections */
    }

    .contact-info-item {
        justify-content: center; /* Center align contact info items */
    }

    .contact-icon {
        margin-right: 0.5vh; /* Adjust margin for contact icons */
        font-size: 1.2rem; /* Adjust font size for contact icons */
    }

    .contact-text {
        font-size: 1rem; /* Adjust font size for contact text */
    }
}

@media only screen and (max-width: 480px) {

    .contact-us {
        height: auto;
    }

    .contact-title {
        font-size: 2rem;
    }

    .form-container {
        width: 90%; /* Adjust the width to fit smaller screens */
    }

    .form-row {
        flex-direction: column; /* Stack each input on top of each other */
        align-items: center; /* Center align the inputs */
    }

    .form-label {
        font-size: 1rem;
    }

    .form-input {
        font-size: 1rem;
    }

    .input-container {
        width: 100%; /* Make each input container take full width */
        margin-bottom: 2vh; /* Add some space between inputs */
    }

    .form-textarea {
        height: 20vh; /* Adjust the height of the textarea */
        font-size: 1rem;
    }

    .form-submit {
        width: 80%; /* Make the submit button take full width */
        padding: 1vh 0; /* Adjust padding for smaller screens */
        font-size: 1.2rem;
    }

    .contact-info {
        flex-direction: column; /* Stack contact info vertically */
        align-items: center; /* Center align contact info */
        width: 90%; /* Adjust contact info width for mobile */
        margin-top: 3vh; /* Adjust margin above contact info */
    }

    .contact-info-left,
    .contact-info-right {
        width: 100%; /* Full width for contact info sections */
        justify-content: center; /* Center align contact info sections */
        margin-bottom: 2vh; /* Add margin between contact info sections */
    }

    .contact-info-item {
        justify-content: center; /* Center align contact info items */
    }

    .contact-icon {
        margin-right: 0.5vh; /* Adjust margin for contact icons */
        font-size: 1.2rem; /* Adjust font size for contact icons */
    }

    .contact-text {
        font-size: 1rem; /* Adjust font size for contact text */
    }
}