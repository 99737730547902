/* Font Faces */
@font-face {
    font-family: 'Poppins-Bold';
    src: url('../Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

/* Default Styles */
/* Default Styles */
.philosophy-page {
    height: 75vh;
    background-color: #061437;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center;
    text-align: center;
    padding-top: 60px;
}

.philosophy-title {
    margin-bottom: 20px;
    font-size: 3rem;
    font-family: 'Poppins-Regular', sans-serif;
    color: #F4E282;
    font-weight: normal;
}

.philosophy-subtitle-container {
    width: 65%; /* Match the text width */
    text-align: left; /* Align subtitle text to the left */
}

.philosophy-subtitle {
    font-size: 1.3rem;
    font-family: 'Poppins-Bold', sans-serif;
    color: #F4E282;
}

.philosophy-text {
    max-width: 65%;
    font-size: 1.3rem;
    font-family: 'Poppins-Regular', sans-serif;
    color: #F4E282;
    text-align: justify;
    margin-top: 1rem; /* Add top margin for spacing */
    margin-bottom: 5rem; /* Add bottom margin for spacing */
}

/* Media Queries */
/* Media Queries */
@media only screen and (max-width: 1280px) {

    .philosophy-page {
        height: auto;
    }

    .philosophy-title {
        font-size: 3rem;
        margin-bottom: 30px;
    }

    .philosophy-subtitle-container {
        width: 90%; /* Adjust width for smaller screens */
    }

    .philosophy-subtitle {
        font-size: 1.8rem;
    }

    .philosophy-text {
        font-size: 1.3rem;
        max-width: 90%;
    }
}

@media only screen and (max-width: 767px) {
    .philosophy-page {
        height: auto;
        background-image: none;
        background-color: #061437;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .philosophy-title {
        font-size: 2.5rem;
        margin-bottom: 30px;
        margin-top: 10px;
    }

    .philosophy-subtitle-container {
        width: 80%; /* Adjust width for smaller screens */
    }

    .philosophy-subtitle {
        font-size: 1.5rem;
    }

    .philosophy-text {
        font-size: 1.1rem;
        max-width: 80%;
    }
}

@media only screen and (max-width: 480px) {
    .philosophy-page {
        height: auto;
        background-image: none;
        background-color: #061437;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .philosophy-title {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    .philosophy-subtitle-container {
        width: 90%; /* Adjust width for smaller screens */
    }

    .philosophy-subtitle {
        font-size: 1.3rem;
    }

    .philosophy-text {
        font-size: 0.8rem;
        max-width: 90%;
    }
}