/* ProjectCard.css */
.project-card-feature {
    width: 45%;
    margin: 2.5%;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.project-image-feature {
    width: 100%;
    height: 550px;
    object-fit: fill; /* Ensure the image covers the entire area without cropping */
}

.project-details-feature {
    padding: 15px;
    text-align: left;
    background-color: #061437;
}

.project-title-feature {
    font-size: 2rem;
    color: #F4E282;
    margin-bottom: 10px;
    font-family: 'Poppins-Light', sans-serif;
    font-weight: normal;
}

.project-info-feature {
    font-size: 1.5rem;
    color: #F4E282;
    margin-bottom: 20px; /* Increase this value to add more space */
    font-family: 'Poppins-Light', sans-serif;
    font-weight: normal;
}

.project-location-feature,
.project-status-feature {
    display: inline-block;
    margin-right: 10px; /* Adjust the gap as needed */
}

.view-project {
    display: block;
    margin: 0 auto;
    padding: 0.4rem 2.3rem;
    background-color: #F4E282;
    color: #061437;
    border: none;
    cursor: pointer;
    font-size: 1.4rem;
    font-family: 'Poppins-Light', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add transition for smooth effect */
    margin-bottom: 20px;
}

.view-project:hover {
    background-color: #061437;
    color: #F4E282;
}

@media only screen and (max-width: 1280px) {
    .project-card-feature {
        width: 100%; /* Adjust width for smaller screens */
        margin: 10px 0; /* Adjust margin for smaller screens */
    }

    .project-image-feature {
        height: 700px; /* Adjust height for smaller screens */
    }

    .project-title-feature {
        font-size: 1.8rem; /* Adjust font size for smaller screens */
    }

    .project-info-feature {
        font-size: 1.3rem; /* Adjust font size for smaller screens */
    }

    .view-project {
        font-size: 1.2rem; /* Adjust font size for smaller screens */
        padding: 0.3rem 2rem; /* Adjust padding for smaller screens */
    }
}

@media only screen and (max-width: 767px) {
    .project-card-feature {
        width: 100%;
        margin: 15px 0;
    }

    .project-image-feature {
        height: 400px;
    }

    .project-title-feature {
        font-size: 1.8rem;
    }

    .project-info-feature {
        font-size: 1.4rem;
    }

    .view-project {
        font-size: 1.4rem;
        padding: 0.4rem 2rem;
    }
}

@media only screen and (max-width: 480px) {
    .project-card-feature {
        width: 100%;
        margin: 10px 0;
    }

    .project-image-feature {
        height: 300px;
    }

    .project-title-feature {
        font-size: 1.5rem;
    }

    .project-info-feature {
        font-size: 1.2rem;
    }

    .view-project {
        font-size: 1.2rem;
        padding: 0.3rem 1.5rem;
    }
}