@font-face {
    font-family: 'Poppins-Bold';
    src: url('../Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'HindMadurai-Bold';
    src: url('../Fonts/Hind_Madurai/HindMadurai-Bold.ttf') format('truetype');
}

.landing-page {
    height: 100vh; /* Full height of the viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #061437; /* Background color */
}

.logo {
    width: 50%; /* Adjust the size as needed */
    max-width: 450px; /* Ensure the logo doesn't get too large */
    opacity: 0;
    transform: scale(0.5); /* Start with a smaller size */
    animation: fadeInGrow 2s forwards;
}

@keyframes fadeInGrow {
    to {
        opacity: 1;
        transform: scale(1); /* Grow to the original size */
    }
}