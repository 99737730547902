@font-face {
    font-family: 'Poppins-Bold';
    src: url('../Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'HindMadurai-Bold';
    src: url('../Fonts/Hind_Madurai/HindMadurai-Bold.ttf') format('truetype');
}

.featured-projects {
    margin-top: 15vh;
    height: 85vh;
    background-color: #ffffff;
    font-family: 'Poppins-Regular', sans-serif;
}

.options,
.sort-filters {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 1.3rem;
    color: #061437;
}

.options .option {
    position: relative;
    cursor: pointer;
}

.options .icon {
    transition: transform 0.3s ease;
}

.options .icon.rotate {
    transform: rotate(180deg);
}

.title-div {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    padding: 20px 0;
}

.title-div .title {
    text-align: left;
    font-family: 'Poppins-Regular', sans-serif;
    font-weight: normal;
    font-size: 3rem;
    color: #061437;
}

.sort-filters {
    margin-top: 20px;
    justify-content: flex-start;
    display: flex;
    /* Ensure the container is a flexbox */
    align-items: center;
    /* Align items vertically in the center */
}

.sort-filters span {
    margin-right: 3rem;
    /* Adjust spacing as needed */
}

.filter-icon {
    margin-left: auto;
    /* Push the filter icon to the right end */
    font-size: 2rem;
}

.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    background-color: #061437;
    border: 1px solid #061437;
    font-size: 1.1rem;
    color: #F4E282;
    z-index: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(-10px);
    text-align: left;
}

.options .dropdown-content,
.sort-option .dropdown-content {
    opacity: 1;
    transform: translateY(0);
}

.dropdown-content .dropdown-item {
    position: relative;
    padding: 5px 0;
    border-bottom: 1px solid #F4E282;
}

.dropdown-content .dropdown-item:last-child {
    border-bottom: none;
    /* Remove the border from the last item */
}

.dropdown-content .dropdown-item .hover-content {
    position: absolute;
    top: 0;
    left: 100%;
    width: 200px;
    margin-left: 10px;
    padding: 10px;
    background-color: #061437;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.hover-content div {
    padding: 5px 0;
    border-bottom: 1px solid #F4E282;
}

.hover-content div:last-child {
    border-bottom: none;
    /* Remove the border from the last item */
}

.sort-option {
    position: relative;
    cursor: pointer;
    margin-right: 3rem;
    /* Adjust spacing as needed */
    display: inline-flex;
    align-items: center;
}

.sort-option span {
    margin: 0;
    /* Remove any margin */
    padding: 0;
    /* Remove any padding */
}

.sort-option .icon {
    margin-left: 0;
    /* Remove any left margin */
    padding-left: 0;
    /* Remove any left padding */
    transition: transform 0.3s ease;
}

.sort-option .icon.rotate {
    transform: rotate(180deg);
}

.selected-filters {
    justify-content: flex-start;
    display: flex;
    /* Ensure the container is a flexbox */
    align-items: center;
    /* Align items vertically in the center */
    width: 85%;
    margin: 0 auto;
    /* Center the div */
    padding: 20px 0;
    /* Add padding similar to sort-filters */
    font-size: 1.3rem;
    /* Match font size */
    color: #061437;
    /* Match text color */
}

.selected-filter {
    display: flex;
    align-items: center;
    background-color: #F4E282;
    color: #061437;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.selected-filter .remove-icon {
    margin-left: 5px;
    cursor: pointer;
}

.clear-filters {
    background-color: #F4E282;
    color: #061437;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.project-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
}

.filter-icon {
    position: relative;
    margin-left: auto;
    /* Push the filter icon to the right end */
    font-size: 2rem;
    cursor: pointer;
    color: #F4E282;
}

.filter-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 200px;
    margin-top: 10px;
    padding: 10px;
    background-color: #061437;
    border: 1px solid #061437;
    font-size: 1.1rem;
    color: #F4E282;
    z-index: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.filter-dropdown .dropdown-item {
    padding: 5px 0;
    border-bottom: 1px solid #F4E282;
    cursor: pointer;
}

.filter-dropdown .dropdown-item:last-child {
    border-bottom: none;
}

.filter-dropdown .dropdown-item .fa-icon {
    margin-right: 10px;
    /* Add some space between the icon and the text */
}

.additional-dropdown {
    position: absolute;
    top: 0;
    left: -250px;
    width: 250px; /* Increased width */
    padding: 10px;
    background-color: #061437;
    border: 1px solid #061437;
    font-size: 1.1rem;
    color: #F4E282;
    z-index: 2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.additional-dropdown-item {
    padding: 5px 0;
    border-bottom: 1px solid #F4E282;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Added to space out label and input */
}

.additional-dropdown-item:last-child {
    border-bottom: none;
}

.additional-dropdown-item input[type="checkbox"] {
    display: none;
}

.additional-dropdown-item input[type="checkbox"] + label::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 2px solid #F4E282;
    border-radius: 3px;
    background-color: #061437;
    vertical-align: middle;
}

.additional-dropdown-item input[type="checkbox"]:checked + label::before {
    background-color: #F4E282;
    border-color: #F4E282;
    content: '✔';
    color: #061437;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
}

.price-input {
    width: 100px; /* Adjusted width for better visibility */
    padding: 5px;
    border: 1px solid #F4E282;
    border-radius: 3px;
    background-color: #061437;
    color: #F4E282;
}

@media only screen and (max-width: 1280px) {
    .featured-projects {
        padding: 15px;
        /* Add padding to the container */
    }

    .options {
        display: flex;
        flex-direction: row;
        /* Display sort options in a row */
        align-items: center;
        /* Center align items */
        justify-content: space-between;
        /* Space between items */
        width: 80%;
        /* Ensure full width */
        text-align: center;
        flex-wrap: wrap;
        /* Allow wrapping to the next line if needed */
    }

    .sort-filters {
        display: flex;
        flex-direction: row;
        /* Display sort options in a row */
        align-items: center;
        /* Center align items */
        justify-content: space-between;
        /* Space between items */
        width: 80%;
        /* Ensure full width */
        text-align: center;
        flex-wrap: wrap;
        /* Allow wrapping to the next line if needed */
    }

    .options .option,
    .sort-filters .sort-option {
        width: auto;
        /* Adjust width for sort options */
        margin-bottom: 15px;
    }

    .title-div {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .title {
        text-align: center;
        margin-left: 0;
        font-size: 3rem;
    }

    .project-cards {
        flex-direction: column;
        align-items: center;
    }

    .project-cards .project-card {
        width: 100%;
        margin-bottom: 25px;
    }

    .selected-filters {
        width: 80%;
        display: flex;
        flex-direction: row;
        /* Display selected filters in a row */
        flex-wrap: wrap;
        /* Allow wrapping to the next line if needed */
        align-items: flex-start;
        /* Align items to the left */
        margin-top: 10px;
        text-align: left;
    }

    .selected-filter,
    .clear-filters {
        margin-bottom: 10px;
        margin-right: 10px;
        /* Add some space between filters */
    }
}

@media only screen and (max-width: 767px) {
    .featured-projects {
        padding: 15px;
    }

    .options {
        flex-direction: column;
        align-items: center; /* Align items to the center */
        width: 100%;
        text-align: center;
    }

    .sort-filters {
        flex-direction: column;
        align-items: flex-start; /* Align items to the center */
        width: 100%;
        text-align: left;
    }

    .options .option,
    .sort-filters .sort-option {
        width: 100%;
        margin-bottom: 15px;
    }

    .title-div {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .title {
        text-align: center;
        margin-left: 0;
        font-size: 2.5rem;
    }

    .project-cards {
        flex-direction: column;
        align-items: center;
    }

    .project-cards .project-card {
        width: 100%;
        margin-bottom: 25px;
    }

    .selected-filters {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
        text-align: left;
        font-size: 1.2rem;
    }

    .selected-filter,
    .clear-filters {
        margin-bottom: 10px;
    }

    .filter-icon {
        position: relative;
        margin-left: 0;
        font-size: 2rem;
        cursor: pointer;
    }

    .filter-dropdown {
        position: static;
        width: 100%;
        margin-top: 10px;
        padding: 10px;
        background-color: #061437;
        border: 1px solid #061437;
        font-size: 1.1rem;
        color: #F4E282;
        z-index: 1;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: left;
    }

    .filter-dropdown .dropdown-item {
        padding: 5px 0;
        border-bottom: 1px solid #F4E282;
        cursor: pointer;
        position: relative; /* Add this */
    }

    .filter-dropdown .dropdown-item:last-child {
        border-bottom: none;
    }

    .filter-dropdown .dropdown-item .fa-icon {
        margin-right: 10px;
    }

    .additional-dropdown {
        position: absolute; /* Change this */
        left: 100%; /* Add this */
        top: 0; /* Add this */
        width: 100%;
        padding: 10px;
        background-color: #061437;
        border: 1px solid #061437;
        font-size: 1.1rem;
        color: #F4E282;
        z-index: 2;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: left;
    }

    .price-range-dropdown { /* Specific class for price range dropdown */
        position: static; /* Change this */
        margin-top: 10px; /* Add this */
    }

    .additional-dropdown-item {
        padding: 5px 0;
        border-bottom: 1px solid #F4E282;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .additional-dropdown-item:last-child {
        border-bottom: none;
    }

    .additional-dropdown-item input[type="checkbox"] {
        display: none;
    }

    .additional-dropdown-item input[type="checkbox"] + label::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        border: 2px solid #F4E282;
        border-radius: 3px;
        background-color: #061437;
        vertical-align: middle;
    }

    .additional-dropdown-item input[type="checkbox"]:checked + label::before {
        background-color: #F4E282;
        border-color: #F4E282;
        content: '✔';
        color: #061437;
        text-align: center;
        line-height: 16px;
        font-size: 12px;
    }

    .price-input {
        width: 100px;
        padding: 5px;
        border: 1px solid #F4E282;
        border-radius: 3px;
        background-color: #061437;
        color: #F4E282;
    }
}

@media only screen and (max-width: 480px) {
    .featured-projects {
        padding: 10px;
    }

    .options {
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .dropdown-content .dropdown-item .hover-content {
        position: absolute;
        top: 100%; /* Position below the dropdown-item */
        left: 10px; /* Add left indent */
        width: 200px;
        margin-left: 10px;
        padding: 10px;
        background-color: #061437;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 2;
    }

    .sort-filters {
        flex-direction: column;
        align-items: flex-start; /* Change this to center */
        width: 100%;
        text-align: left;
    }

    .options .option,
    .sort-filters .sort-option {
        width: 100%;
        margin-bottom: 10px;
    }

    .title-div {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .title {
        text-align: center;
        margin-left: 0;
        font-size: 2rem;
    }

    .project-cards {
        flex-direction: column;
        align-items: center;
    }

    .project-cards .project-card {
        width: 100%;
        margin-bottom: 20px;
    }

    .selected-filters {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
        text-align: left;
        font-size: 1.2rem;
    }

    .selected-filter,
    .clear-filters {
        margin-bottom: 10px;
    }

    .filter-icon {
        position: relative;
        margin-left: 0;
        font-size: 2rem;
        cursor: pointer;
    }

    .filter-dropdown {
        position: static;
        width: 100%;
        margin-top: 10px;
        padding: 10px;
        background-color: #061437;
        border: 1px solid #061437;
        font-size: 1.1rem;
        color: #F4E282;
        z-index: 1;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: left;
    }

    .filter-dropdown .dropdown-item {
        padding: 5px 0;
        border-bottom: 1px solid #F4E282;
        cursor: pointer;
        position: relative; /* Add this */
    }

    .filter-dropdown .dropdown-item:last-child {
        border-bottom: none;
    }

    .filter-dropdown .dropdown-item .fa-icon {
        margin-right: 10px;
    }

    .additional-dropdown {
        position: absolute; /* Change this */
        left: 100%; /* Add this */
        top: 0; /* Add this */
        width: 100%;
        padding: 10px;
        background-color: #061437;
        border: 1px solid #061437;
        font-size: 1.1rem;
        color: #F4E282;
        z-index: 2;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: left;
    }

    .price-range-dropdown { /* Specific class for price range dropdown */
        position: static; /* Change this */
        margin-top: 10px; /* Add this */
    }

    .additional-dropdown-item {
        padding: 5px 0;
        border-bottom: 1px solid #F4E282;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .additional-dropdown-item:last-child {
        border-bottom: none;
    }

    .additional-dropdown-item input[type="checkbox"] {
        display: none;
    }

    .additional-dropdown-item input[type="checkbox"] + label::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        border: 2px solid #F4E282;
        border-radius: 3px;
        background-color: #061437;
        vertical-align: middle;
    }

    .additional-dropdown-item input[type="checkbox"]:checked + label::before {
        background-color: #F4E282;
        border-color: #F4E282;
        content: '✔';
        color: #061437;
        text-align: center;
        line-height: 16px;
        font-size: 12px;
    }

    .price-input {
        width: 100px;
        padding: 5px;
        border: 1px solid #F4E282;
        border-radius: 3px;
        background-color: #061437;
        color: #F4E282;
    }
}