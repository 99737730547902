@font-face {
    font-family: 'Poppins-Bold';
    src: url('../Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'HindMadurai-Bold';
    src: url('../Fonts/Hind_Madurai/HindMadurai-Bold.ttf') format('truetype');
}

/* General Styles */
.projects-overview {
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Distribute space evenly */
    padding: 2vh 0; /* Optional: Add some padding to the top and bottom */
}

.po-title {
    margin-top: 2vh; /* Increase the top margin using vh */
    text-align: center;
    font-family: 'Poppins-Regular', sans-serif;
    color: #061437;
    font-size: 3rem;
    font-weight: normal;
}

.po-rect-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.po-rect1 {
    width: 25%;
    height: 30vh; /* Use vh for height */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensure content does not overflow */
    padding: 1rem; /* Add padding */
    box-sizing: border-box; /* Include padding in the element's total width and height */
    background-color: #ffffff;
}

/* Apply the animation to the counter text */
.po-text-large-1 {
    text-align: center;
    font-size: 5rem; /* Adjust font size to fit */
    font-family: 'Poppins-Regular', sans-serif;
    margin: 0; /* Remove default margin */
    color: #061437;
}

.po-text-small-1 {
    text-align: center;
    font-size: 1.7rem; /* Adjust font size to fit */
    font-family: 'Poppins-Regular', sans-serif;
    margin: 0; /* Remove default margin */
    color: #061437;
}

.po-below-rect-text {
    text-align: center;
    font-size: 1.3rem;
    font-family: 'Poppins-Regular', sans-serif;
    color: #061437;
    text-align: justify;
    max-width: 65%;
    margin-top: 1rem; /* Adjust margin to reduce gap */
    margin-bottom: 2rem; /* Reduce bottom margin */
}

/* Button Styles */
.po-button-container {
    display: flex;
    justify-content: center;
    gap: 1rem; /* Add gap between buttons */
    margin-bottom: 4vh;
    margin-top: 0; /* Reduce top margin */
}

.po-center-button {
    padding: 1rem 6rem; /* Use rem for padding */
    font-size: 1.5rem; /* Use rem for font size */
    cursor: pointer;
    text-align: center;
    font-family: 'Poppins-Regular', sans-serif;
    background-color: #061437;
    color: #F4E282;
    border: none;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add transition for smooth effect */
}

.po-center-button:hover {
    background-color: #F4E282; /* Change background color on hover */
    color: #061437; /* Change text color on hover */
}

@media only screen and (max-width: 1280px) {
    .projects-overview {
        height: auto; /* Adjust height for mobile */
        padding: 2vh 1rem; /* Add padding for mobile */
    }

    .po-title {
        font-size: 3rem; /* Adjust font size */
        margin-top: 4vh; /* Adjust top margin */
    }

    .po-rect1 {
        width: 60%; /* Adjust width */
        height: auto; /* Adjust height */
        padding: 1rem; /* Adjust padding */
    }

    .po-text-large-1 {
        font-size: 3.5rem; /* Adjust font size */
    }

    .po-text-small-1 {
        font-size: 1.4rem; /* Adjust font size */
    }

    .po-below-rect-text {
        font-size: 1.3rem; /* Adjust font size */
        max-width: 90%; /* Adjust max width */
        margin-top: 1rem; /* Adjust margin */
        margin-bottom: 2rem; /* Increase bottom margin to create gap */
    }

    .po-button-container {
        flex-direction: column; /* Stack buttons vertically */
        align-items: center; /* Center align buttons */
        gap: 1rem; /* Add gap between buttons */
        margin-bottom: 2vh; /* Adjust bottom margin */
    }

    .po-center-button {
        width: 100%; /* Make buttons full width */
        padding: 1rem; /* Adjust padding */
        font-size: 1.5rem; /* Adjust font size */
    }
}

@media only screen and (max-width: 767px) {
    .projects-overview {
        height: auto; /* Adjust height for mobile */
        padding: 2vh 1rem; /* Add padding for mobile */
    }

    .po-title {
        font-size: 2.5rem; /* Adjust font size */
        margin-top: 4vh; /* Adjust top margin */
    }

    .po-rect1 {
        width: 70%; /* Adjust width */
        height: auto; /* Adjust height */
        padding: 1rem; /* Adjust padding */
    }

    .po-text-large-1 {
        font-size: 3rem; /* Adjust font size */
    }

    .po-text-small-1 {
        font-size: 1.3rem; /* Adjust font size */
    }

    .po-below-rect-text {
        font-size: 1.1rem; /* Adjust font size */
        max-width: 80%; /* Adjust max width */
        margin-top: 1rem; /* Adjust margin */
        margin-bottom: 2rem; /* Increase bottom margin to create gap */
    }

    .po-button-container {
        flex-direction: column; /* Stack buttons vertically */
        align-items: center; /* Center align buttons */
        gap: 1rem; /* Add gap between buttons */
        margin-bottom: 2vh; /* Adjust bottom margin */
    }

    .po-center-button {
        width: 100%; /* Make buttons full width */
        padding: 1rem; /* Adjust padding */
        font-size: 1.3rem; /* Adjust font size */
    }
}

@media only screen and (max-width: 480px) {
    .projects-overview {
        height: auto; /* Adjust height for mobile */
        padding: 2vh 1rem; /* Add padding for mobile */
    }

    .po-title {
        font-size: 2rem; /* Adjust font size */
        margin-top: 4vh; /* Adjust top margin */
    }

    .po-rect1 {
        width: 80%; /* Adjust width */
        height: auto; /* Adjust height */
        padding: 1rem; /* Adjust padding */
    }

    .po-text-large-1 {
        font-size: 2.5rem; /* Adjust font size */
    }

    .po-text-small-1 {
        font-size: 1rem; /* Adjust font size */
    }

    .po-below-rect-text {
        font-size: 0.8rem; /* Adjust font size */
        max-width: 100%; /* Adjust max width */
        margin-top: 1rem; /* Adjust margin */
        margin-bottom: 2rem; /* Increase bottom margin to create gap */
    }

    .po-button-container {
        flex-direction: column; /* Stack buttons vertically */
        align-items: center; /* Center align buttons */
        gap: 1rem; /* Add gap between buttons */
        margin-bottom: 2vh; /* Adjust bottom margin */
    }

    .po-center-button {
        width: 100%; /* Make buttons full width */
        padding: 1rem; /* Adjust padding */
        font-size: 1rem; /* Adjust font size */
    }
}