.maha-section {
    background-color: #061437;
    padding: 20px;
    color: #F4E282;
}

.title {
    color: #F4E282;
    text-align: center;
    margin-bottom: 20px;
}

.custom-table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
}

.custom-table th, .custom-table td {
    border: 1px solid #F4E282;
    padding: 10px;
    text-align: left;
}

.custom-table th {
    width: 85%;
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 1rem;
}

.custom-table td {
    width: 15%;
    text-align: center;
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1rem;
}

.custom-table th, .custom-table td {
    color: #F4E282;
}

.custom-table .fa-file-pdf {
    color: #F4E282;
    font-size: 2rem;
    cursor: pointer;
}

.custom-table td:first-child {
    text-align: left;
}

.custom-table a {
    text-decoration: none;
    color: inherit; /* Ensures the link inherits the text color */
}

@media only screen and (max-width: 1280px) {
    /* No changes needed, keep the default layout */
}

@media only screen and (max-width: 767px) {
    .custom-table {
        width: 100%;
    }

    .custom-table th, .custom-table td {
        display: block;
        width: 100%;
        text-align: left;
    }

    .custom-table th {
        font-size: 1rem;
    }

    .custom-table td {
        font-size: 1rem;
        text-align: left;
    }

    .custom-table .fa-file-pdf {
        font-size: 1.8rem;
    }

    .custom-table tr {
        margin-bottom: 10px;
        display: block;
        border-bottom: 1px solid #F4E282;
    }

    .custom-table thead {
        display: none;
    }

    /* Center-align the PDF icon */
    .custom-table td:last-child {
        text-align: center;
    }
}

@media only screen and (max-width: 480px) {
    .custom-table {
        width: 100%;
    }

    .custom-table th, .custom-table td {
        display: block;
        width: 100%;
        text-align: left;
    }

    .custom-table th {
        font-size: 0.9rem;
    }

    .custom-table td {
        font-size: 0.9rem;
        text-align: left;
    }

    .custom-table .fa-file-pdf {
        font-size: 1.5rem;
    }

    .custom-table tr {
        margin-bottom: 10px;
        display: block;
        border-bottom: 1px solid #F4E282;
    }

    .custom-table thead {
        display: none;
    }

    .custom-table td:last-child {
        text-align: center;
    }
}