/* Font Faces */
@font-face {
    font-family: 'Poppins-Bold';
    src: url('../Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

/* Default Styles */
.about-page {
    height: 100vh;
    background-image: url('/public/Images/GaganGiriSection2Bg.png');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    text-align: center;
    padding-top: 60px;
}

.about-title {
    margin-bottom: 40px;
    font-size: 3rem;
    font-family: 'Poppins-Regular', sans-serif;
    color: #F4E282;
    font-weight: normal;
}

.about-text {
    max-width: 65%;
    font-size: 1.3rem;
    font-family: 'Poppins-Regular', sans-serif;
    color: #F4E282;
    text-align: justify;
    margin-top: 20px; /* Add top margin for spacing */
    margin-bottom: 20px; /* Add bottom margin for spacing */
}

/* Media Queries */
@media only screen and (max-width: 1280px) {

    .about-page {
        height: auto;
    }

    .about-title {
        font-size: 2.5rem;
        margin-bottom: 30px;
    }

    .about-text {
        font-size: 1.3rem;
        max-width: 90%;
    }
}

@media only screen and (max-width: 767px) {
    .about-page {
        height: auto;
        background-image: none;
        background-color: #061437;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .about-title {
        font-size: 2.5rem;
        margin-bottom: 30px;
        margin-top: 10px;
    }

    .about-text {
        font-size: 1.1rem;
        max-width: 90%;
    }
}

@media only screen and (max-width: 480px) {
    .about-page {
        height: auto;
        background-image: none;
        background-color: #061437;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .about-title {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    .about-text {
        font-size: 0.8rem;
        max-width: 90%;
    }
}

