@font-face {
    font-family: 'Poppins-Bold';
    src: url('../Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'HindMadurai-Bold';
    src: url('../Fonts/Hind_Madurai/HindMadurai-Bold.ttf') format('truetype');
}

/* General Styles */
.company-profile {
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Distribute space evenly */
    padding: 2vh 0; /* Optional: Add some padding to the top and bottom */
}

.cp-title {
    margin-top: 2vh; /* Increase the top margin using vh */
    text-align: center;
    font-family: 'Poppins-Regular', sans-serif;
    color: #061437;
    font-size: 3rem;
    font-weight: normal;
}

.cp-rect-container {
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    width: 100%;
}

.cp-rect {
    width: 25%;
    height: 35vh; /* Use vh for height */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensure content does not overflow */
    padding: 1rem; /* Add padding */
    box-sizing: border-box; /* Include padding in the element's total width and height */
}

.rect1 {
    background-color: #061437;
}

.rect2 {
    background-color: #FFFFFF;
}

.cp-text-large-1, .cp-text-large-2 {
    text-align: center;
    font-size: 4rem; /* Adjust font size to fit */
    font-family: 'Poppins-Regular', sans-serif;
    margin: 0; /* Remove default margin */
}

.cp-text-large-1 {
    color: #F4E282;
}

.cp-text-large-2 {
    color: #061437;
}

.cp-text-small-1, .cp-text-small-2 {
    text-align: center;
    font-size: 1.5rem; /* Adjust font size to fit */
    font-family: 'Poppins-Regular', sans-serif;
    margin: 0; /* Remove default margin */
}

.cp-text-small-1 {
    color: #F4E282;
}

.cp-text-small-2 {
    color: #061437;
}

.cp-below-rect-text {
    margin-top: 5vh;
    text-align: center;
    font-size: 1.3rem;
    font-family: 'Poppins-Regular', sans-serif;
    color: #061437;
    text-align: justify;
    max-width: 65%;
}

/* Button Styles */
.cp-center-button {
    position: relative;
    padding: 1rem 6rem; /* Use rem for padding */
    font-size: 1.5rem; /* Use rem for font size */
    cursor: pointer;
    text-align: center;
    margin-bottom: 4vh;
    font-family: 'Poppins-Regular', sans-serif;
    background-color: #061437;
    color: #F4E282;
    border: none;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add transition for smooth effect */
    margin-top: 5vh;
}

.cp-center-button:hover {
    background-color: #F4E282; /* Change background color on hover */
    color: #061437; /* Change text color on hover */
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-up {
    opacity: 0;
    animation: fadeInUp 1.5s ease-out 0.3s forwards; /* 1.5s duration, 0.3s delay */
}

/* Media Query for screen sizes up to 1024px */
@media only screen and (max-width: 1280px) {
    .company-profile {
        height: auto; /* Adjust height to auto */
        padding: 2vh 0; /* Maintain padding */
    }

    .cp-title {
        font-size: 2.5rem; /* Adjust font size */
        margin-top: 5vh; /* Adjust top margin */
        margin-bottom: 3vh;
    }

    .cp-rect-container {
        flex-direction: row; /* Stack rectangles horizontally */
        flex-wrap: wrap; /* Allow wrapping if necessary */
        justify-content: center; /* Center align items */
        margin-bottom: 3vh;
    }

    .cp-rect {
        width: 45%; /* Adjust width */
        height: 30vh; /* Adjust height */
        margin: 1vh; /* Add margin between rectangles */
    }

    .cp-text-large-1, .cp-text-large-2 {
        font-size: 3.5rem; /* Adjust font size */
    }

    .cp-text-small-1, .cp-text-small-2 {
        font-size: 1.4rem; /* Adjust font size */
    }

    .cp-below-rect-text {
        font-size: 1.3rem; /* Adjust font size */
        max-width: 90%; /* Adjust max-width */
        margin-bottom: 3vh;
    }

    .cp-center-button {
        padding: 0.8rem 3rem; /* Adjust padding */
        font-size: 1.5rem; /* Adjust font size */
        margin-bottom: 3vh; /* Adjust bottom margin */
    }
}

/* Media Query for devices with a max-width of 767px */
@media only screen and (max-width: 767px) {
    .company-profile {
        height: auto; /* Adjust height to auto for better fit */
        padding: 2vh 1rem; /* Add some padding */
    }

    .cp-title {
        font-size: 2.8rem; /* Adjust font size */
        margin-top: 6vh; /* Adjust top margin */
        margin-bottom: 2vh; /* Add bottom margin to create space */
    }

    .cp-rect-container {
        flex-direction: column; /* Stack the rectangles vertically */
        align-items: center; /* Center align the rectangles */
    }

    .cp-rect {
        width: 50%; /* Adjust width */
        height: auto; /* Adjust height to auto */
        margin-bottom: 2vh; /* Add margin between rectangles */
    }

    .cp-text-large-1, .cp-text-large-2 {
        font-size: 3rem; /* Adjust font size */
    }

    .cp-text-small-1, .cp-text-small-2 {
        font-size: 1.3rem; /* Adjust font size */
    }

    .cp-below-rect-text {
        font-size: 1.1rem; /* Adjust font size */
        max-width: 90%; /* Adjust max-width */
    }

    .cp-center-button {
        padding: 1rem 2rem; /* Adjust padding */
        font-size: 1.3rem; /* Adjust font size */
        margin-bottom: 2vh; /* Adjust bottom margin */
        margin-top: 2vh; /* Add top margin to create space */
    }
}

/* Media Query for devices with a max-width of 480px */
@media only screen and (max-width: 480px) {
    .company-profile {
        height: auto; /* Adjust height to auto for better fit */
    }

    .cp-title {
        font-size: 2rem; /* Adjust font size */
        margin-top: 4vh; /* Adjust top margin */
        margin-bottom: 2vh; /* Add bottom margin to create space */
    }

    .cp-rect-container {
        flex-direction: column; /* Stack the rectangles vertically */
        align-items: center; /* Center align the rectangles */
    }

    .cp-rect {
        width: 60%; /* Adjust width */
        height: auto; /* Adjust height to auto */
        margin-bottom: 2vh; /* Add margin between rectangles */
    }

    .cp-text-large-1, .cp-text-large-2 {
        font-size: 2.5rem; /* Adjust font size */
    }

    .cp-text-small-1, .cp-text-small-2 {
        font-size: 1rem; /* Adjust font size */
    }

    .cp-below-rect-text {
        font-size: 0.8rem; /* Adjust font size */
        max-width: 90%; /* Adjust max-width */
    }

    .cp-center-button {
        padding: 1rem 2rem; /* Adjust padding */
        font-size: 1rem; /* Adjust font size */
        margin-bottom: 2vh; /* Adjust bottom margin */
        margin-top: 2vh; /* Add top margin to create space */
    }
}