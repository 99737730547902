@font-face {
    font-family: 'HindMadurai-Bold';
    src: url('../Fonts/Hind_Madurai/HindMadurai-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('../Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

.executive-profile {
    margin-top: 15vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFFFFF;
}

.exec-title {
    margin-top: 20px;
    text-align: center;
    font-family: 'Poppins-Regular', sans-serif;
    color: #061437;
    font-size: 3rem;
    font-weight: normal;
}

.profile-container, .profile-container-2 {
    display: flex;
    width: 90%;
    height: 80vh;
    gap: 20px; /* Add spacing between left and right sections */
}

.left-section, .right-section, .left-section-2, .right-section-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.left-section {
    flex: 1;
}

.right-section {
    flex: 2;
}

.left-section-2 {
    flex: 2;
}

.right-section-2 {
    flex: 1;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    width: 100%;
    height: 70vh;
}

.profile-image {
    width: 100%;
    height: 85%;
    object-fit: cover;
}

.card-info {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 0;
    background-color: #061437;
}

.card-info h2 {
    margin: 0;
    font-family: 'Poppins-Regular', sans-serif;
    color: #F4E282;
    font-weight: normal;
    font-size: 1.3rem;
}

.card-info p {
    margin: 0;
    font-family: 'Poppins-Regular', sans-serif;
    color: #F4E282;
    font-size: 1rem;
}

.profile-info {
    text-align: justify;
    width: 80%;
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 1.3rem;
    margin-bottom: 20px; /* Add this line to create space between paragraphs */
    color: #061437;
}

@media only screen and (max-width: 1600px) {
    .profile-container, .profile-container-2 {
        flex-direction: row;
        height: auto;
        margin-bottom: 20px; /* Add spacing between profile containers */
        gap: 20px; /* Add spacing between sections in column layout */
        margin-top: 1rem;
    }

    .profile-image {
        width: 100%;
        height: 95%;
        object-fit: cover;
    }

    .profile-info {
        text-align: justify;
        width: 80%;
        font-family: 'Poppins-Regular', sans-serif;
        font-size: 1.1rem;
        margin-bottom: 20px; /* Add this line to create space between paragraphs */
        color: #061437;
    }
}

@media only screen and (max-width: 1280px) {
    .profile-container, .profile-container-2 {
        flex-direction: column;
        height: auto;
        margin-bottom: 20px; /* Add spacing between profile containers */
        gap: 20px; /* Add spacing between sections in column layout */
    }

    .left-section, .right-section, .left-section-2, .right-section-2 {
        width: 100%;
        height: auto;
    }

    .profile-container-2 {
        flex-direction: column-reverse;
    }

    .card {
        width: 90%;
        height: auto;
    }

    .profile-image {
        height: auto;
        min-height: 550px; /* Set a minimum height for the image */
    }

    .card-info {
        height: auto;
        padding: 10px 0;
    }

    .profile-info {
        width: 90%;
        font-size: 1.3rem;
    }

    .exec-title {
        font-size: 3rem;
        margin-bottom: 20px;
    }
}

/* Media Query for screens up to 767px */
@media only screen and (max-width: 767px) {
    .profile-container, .profile-container-2 {
        flex-direction: column;
        height: auto;
        margin-bottom: 20px; /* Add spacing between profile containers */
        gap: 20px; /* Add spacing between sections in column layout */
    }

    .left-section, .right-section, .left-section-2, .right-section-2 {
        width: 100%;
        height: auto;
    }

    .profile-container-2 {
        flex-direction: column-reverse;
    }

    .card {
        width: 90%;
        height: auto;
    }

    .profile-image {
        height: auto;
        min-height: 400px; /* Set a minimum height for the image */
    }

    .card-info {
        height: auto;
        padding: 10px 0;
    }

    .profile-info {
        width: 90%;
        font-size: 1.1rem;
    }

    .exec-title {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
}

/* Media Query for screens up to 480px */
@media only screen and (max-width: 480px) {
    .profile-container, .profile-container-2 {
        flex-direction: column;
        height: auto;
        margin-bottom: 20px; /* Add spacing between profile containers */
        gap: 20px; /* Add spacing between sections in column layout */
    }

    .left-section, .right-section, .left-section-2, .right-section-2 {
        width: 100%;
        height: auto;
    }

    .profile-container-2 {
        flex-direction: column-reverse;
    }

    .card {
        width: 90%;
        height: auto;
    }

    .profile-image {
        height: auto;
        min-height: 300px; /* Set a minimum height for the image */
    }

    .card-info {
        height: auto;
        padding: 10px 0;
    }

    .profile-info {
        width: 90%;
        font-size: 1rem;
    }

    .exec-title {
        font-size: 2rem;
        margin-bottom: 20px;
    }
}