@font-face {
    font-family: 'Poppins-Bold';
    src: url('../Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'HindMadurai-Bold';
    src: url('../Fonts/Hind_Madurai/HindMadurai-Bold.ttf') format('truetype');
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.project-details {
    margin-top: 10vh; /* Adjusted to account for the fixed navbar */
    min-height: 100vh; /* Ensure it covers the full viewport height */
    background-color: #ffffff;
    padding: 0; /* Removed padding to ensure full coverage */
    font-family: 'Poppins-Regular', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.project-main-image {
    width: 100%;
    height: 70vh; /* Fixed height */
    position: relative; /* Added to position the button absolutely */
    overflow: hidden; /* Ensure the image does not overflow */
}

.project-main-image-img {
    width: 100%;
    height: 100%;
    object-fit: fill; /* Ensure the image covers the entire area without cropping */
}

.enquire-button {
    margin-top: 2rem; /* Adjust as needed to position below the image */
    background-color: #F4E282;
    color: #061437;
    border: none;
    padding: 0.7rem 3rem;
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: normal;
}

.enquire-button:hover {
    background-color: #061437; /* Slightly darker shade for hover effect */
    color: #F4E282;
}

.project-info {
    width: 70%;
    height: 50vh; /* Fixed height */
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the rows within the container */
    justify-content: space-between; /* Distribute space evenly between rows */
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Ensure the row takes up the full width of the container */
    flex-grow: 1; /* Allow the row to grow and take up available space */
}

.row-top {
    align-items: flex-start; /* Align items to the top */
}

.row-center {
    align-items: center; /* Vertically center the content within the row */
}

.project-name {
    flex: 1;
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 3rem;
    color: #061437;
    text-align: left;
}

.project-description {
    flex: 2;
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 1.3rem;
    color: #061437;
    text-align: left;
    margin-top: 0.5rem; /* Adjust the top margin to align with Status and Area */
}

.info-block {
    flex: 1;
    text-align: left;
}

.label {
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1rem;
    color: #061437;
    margin-bottom: 0.5rem;
}

.label-project-name-rera {
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1rem;
    color: #061437;
    font-weight: normal;
    margin-top: 1rem;
}

.info-project-name-rera {
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1.5rem;
    color: #061437;
}

.info {
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 1.5rem;
    color: #061437;
}

/* ProjectDetails.css */
.image-slider {
    width: 75%;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 2rem;
}

.slider-card {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
}

.slider-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.slider-image-container.active {
    opacity: 1;
    position: relative;
}

.slider-image {
    width: 100%; /* Ensure the image takes up the full width of the container */
    height: 100%; /* Ensure the image takes up the full height of the container */
    object-fit: fill; /* Stretch the image to fill the container */
    opacity: 0; /* Start with opacity 0 */
    transform: scale(0.95); /* Start with a slight scale down */
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Add transition */
}

.slider-image.active {
    opacity: 1; /* Fade in */
    transform: scale(1); /* Scale to normal size */
}

.slider-icon {
    color: #061437;
    font-size: 2rem;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;
}

.slider-icon:hover {
    transform: scale(1.2);
    color: #F4E282;
}

.slider-icon {
    font-size: 5rem;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.image-text, .image-description {
    opacity: 0;
    transform: translateY(20px);
}

.image-text.animating, .image-description.animating {
    animation: fadeInUp 0.5s ease-in-out forwards;
}

.image-text.active, .image-description.active {
    opacity: 1;
    transform: translateY(0);
}

.image-text {
    font-family: 'Poppins-Light', sans-serif;
    font-size: 2rem;
    color: #061437;
    margin-top: 1rem;
    text-align: center;
}

.image-description {
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 1.3rem;
    color: #061437;
    margin-top: 0.5rem;
    text-align: center;
}

.image-indicator {
    position: absolute;
    margin-top: 1rem;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.5rem;
}

.indicator-dot {
    width: 10px;
    height: 10px;
    background-color: #061437;
    border-radius: 50%;
}

.indicator-dot.active {
    opacity: 1;
    background-color: #F4E282;
}

.image-details {
    width: 75%;
    text-align: center;
    margin-top: 1rem;
    height: 10vh;
}

.amenities-section {
    width: 100%;
    padding: 2rem 0;
    background-color: #ffffff; /* Light background color */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.amenities-title {
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 3rem;
    color: #061437;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: normal;
}

.amenities-list {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust the min width as needed */
    gap: 1rem; /* Adjust the gap between items as needed */
    align-items: start; /* Align items to the start of the grid cells */
}

.amenity-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 1rem;
}

.amenity-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    cursor: pointer;
    transition: color 0.3s ease; /* Smooth transition for color change */
}

.amenity-text {
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 1.3rem;
    color: #061437;
    transition: color 0.3s ease; /* Smooth transition for color change */
}

.amenity-icon {
    color: #061437;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color change and rotation */
}

/* Hover state */
.amenity-header:hover .amenity-text {
    color: #F4E282;
}

/* Expanded state */
.amenity-description {
    width: 100%;
    padding: 0;
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 1rem;
    color: #061437;
    text-align: center;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: height 0.5s ease, opacity 0.5s ease, padding 0.5s ease; /* Smooth transition for expansion */
}

.amenity-row.expanded .amenity-description {
    padding: 0.5rem 0; /* Add padding when expanded */
    opacity: 1;
}

.location-section {
    display: flex;
    width: 100%;
    padding: 2rem 0;
    background-color: #ffffff; /* Light background color */
}

.location-left {
    flex: 1; /* Adjust the flex value to control the width */
    padding: 1rem;
}

.location-right {
    flex: 2; /* Adjust the flex value to control the width */
    display: flex;
    justify-content: center;
    align-items: center;
}

.location-title {
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 3rem;
    color: #061437;
    margin-bottom: 2rem; /* Increased margin to push rows down */
    text-align: left;
    font-weight: normal;
}

.location-headings {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 1.2rem;
    color: #061437;
}

.location-heading, .duration-heading {
    font-weight: bold;
}

.location-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #061437; /* Line between rows */
    padding-bottom: 0.5rem;
}

.location-name, .time-to-location {
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 1.2rem;
    color: #061437;
}

.location-map {
    width: 100%;
    height: 60vh;
    border: none; /* Remove border from iframe */
}

.floor-plans-section {
    width: 100%;
    height: 70vh;
    padding: 2rem 0;
    background-color: #ffffff; /* Light background color */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15rem;
}

.floor-plans-title {
    font-family: 'Poppins-Regular', sans-serif;
    font-size: 3rem;
    color: #061437;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: normal;
}

.disclaimer-section {
    width: 100%;
    background-color: #061437;
    text-align: justify;
    font-family: 'Poppins-Light', sans-serif;
    padding: 20px; /* Optional: Add some padding for better readability */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.disclaimer-button {
    width: 17%;
    background-color: #F4E282;
    color: #061437;
    border: none;
    padding: 0.7rem 3rem;
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: normal;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #061437;
    color: #F4E282;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    overflow-y: auto; /* Allow y-axis scrolling */
    max-width: 600px;
    position: relative;
    font-family: 'Poppins-Light', sans-serif;
}

.close {
    color: #F4E282;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.disclaimer-section-content {
    margin-top: 10px; /* Add margin to push the text below the close icon */
    padding: 10px;
}

.project-details-contact-us {
    margin-top: 10rem;
    width: 100%;
}

@media only screen and (max-width: 1600px) {
    .row {
        margin-bottom: 2vh;
    }

    .image-slider {
        margin-top: 10vh;
    }

    .amenities-title {
        font-size: 2.5rem;
    }

    .location-title {
        font-size: 2.5rem;
    }

    .floor-plans-title {
        font-size: 2.5rem;
    }

    .project-details-contact-us {
        height: 90vh;
    }
}

@media only screen and (max-width: 1280px) {
    .project-details {
        margin-top: 80px;
    }

    .project-main-image {
        height: 80vh;
    }

    .label-project-name-rera {
        font-size: 1rem;
    }
    
    .info-project-name-rera {
        font-size: 1.3rem;
    }

    .enquire-button {
        bottom: 1.5rem;
        padding: 0.5rem 1.5rem;
        font-size: 1.3rem;
    }

    .project-info {
        width: 95%;
        height: auto;
        flex-direction: column;
    }

    .row {
        flex-direction: row;
        align-items: center;
        margin-top: 1.5vh;
    }

    .project-name {
        font-size: 2rem;
    }

    .project-description {
        font-size: 1.3rem;
    }

    .info-block {
        flex: 1;
        text-align: left;
        margin-bottom: 0.8rem;
    }

    .label {
        font-size: 1rem;
    }

    .info {
        font-size: 1.3rem;
    }

    .image-slider {
        width: 100%;
        height: auto;
    }

    .slider-card {
        width: 100%;
    }

    .slider-icon {
        font-size: 4rem;
    }

    .image-text {
        font-size: 1.5rem;
    }

    .image-description {
        font-size: 1rem;
    }

    .amenities-list {
        width: 95%;
    }

    .amenities-title {
        font-size: 3rem;
    }

    .amenity-text {
        font-size: 1.3rem;
    }

    .amenity-description {
        font-size: 1rem;
    }

    .floor-plans-section {
        margin-bottom: 1vh;
    }

    .floor-plans-title {
        font-size: 3rem;
    }

    .contact-us-section-project {
        height: auto;
    }

    .image-indicator {
        position: static;
        transform: none;
        margin-top: 0.8rem;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .floor-plans-section .slider-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .floor-plans-section .slider-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .floor-plans-section .image-indicator {
        position: static;
        transform: none;
        margin-top: 0.8rem;
        display: flex;
        justify-content: center;
    }

    .floor-plans-section .image-text,
    .floor-plans-section .image-description {
        text-align: center;
        align-self: flex-start;
    }

    .location-section {
        flex-direction: column;
        align-items: center; /* Center align the location section */
        text-align: center; /* Center align text within the section */
    }

    .location-left, .location-right {
        width: 100%;
        padding: 1rem;
        justify-content: center;
        align-items: center;
    }

    .location-title {
        font-size: 3rem;
    }

    .location-headings {
        font-size: 1.3rem;
    }

    .location-name, .time-to-location {
        font-size: 1.3rem;
    }

    .location-map {
        height: 40vh;
    }

    .floor-plans-section {
        margin-bottom: 55vh;
    }

    .modal-content {
        width: 90%;
        max-height: 70%; /* Adjust the height for smaller screens */
    }

    .disclaimer-button {
        width: 15%; /* Adjust button width for smaller screens */
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 1024px) {
    .project-details {
        margin-top: 125px;
    }

    .project-main-image {
        height: 30vh;
    }

    .label-project-name-rera {
        font-size: 1rem;
    }
    
    .info-project-name-rera {
        font-size: 1.3rem;
    }

    .enquire-button {
        bottom: 1.5rem;
        padding: 0.5rem 1.5rem;
        font-size: 1.3rem;
    }

    .project-info {
        width: 95%;
        height: auto;
        flex-direction: column;
    }

    .row {
        flex-direction: row;
        align-items: center;
        margin-top: 1.5vh;
    }

    .project-name {
        font-size: 2rem;
    }

    .project-description {
        font-size: 1.3rem;
    }

    .info-block {
        flex: 1;
        text-align: left;
        margin-bottom: 0.8rem;
    }

    .label {
        font-size: 1rem;
    }

    .info {
        font-size: 1.3rem;
    }

    .image-slider {
        width: 100%;
        height: auto;
    }

    .slider-card {
        width: 100%;
    }

    .slider-icon {
        font-size: 4rem;
    }

    .image-text {
        font-size: 1.5rem;
    }

    .image-description {
        font-size: 1rem;
    }

    .amenities-list {
        width: 95%;
    }

    .amenities-title {
        font-size: 3rem;
    }

    .amenity-text {
        font-size: 1.3rem;
    }

    .amenity-description {
        font-size: 1rem;
    }

    .floor-plans-section {
        margin-bottom: 1vh;
    }

    .floor-plans-title {
        font-size: 3rem;
    }

    .contact-us-section-project {
        height: auto;
    }

    .image-indicator {
        position: static;
        transform: none;
        margin-top: 0.8rem;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .floor-plans-section .slider-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .floor-plans-section .slider-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .floor-plans-section .image-indicator {
        position: static;
        transform: none;
        margin-top: 0.8rem;
        display: flex;
        justify-content: center;
    }

    .floor-plans-section .image-text,
    .floor-plans-section .image-description {
        text-align: center;
        align-self: flex-start;
    }

    .location-section {
        flex-direction: column;
        align-items: center; /* Center align the location section */
        text-align: center; /* Center align text within the section */
    }

    .location-left, .location-right {
        width: 100%;
        padding: 1rem;
        justify-content: center;
        align-items: center;
    }

    .location-title {
        font-size: 3rem;
    }

    .location-headings {
        font-size: 1.3rem;
    }

    .location-name, .time-to-location {
        font-size: 1.3rem;
    }

    .location-map {
        height: 40vh;
    }

    .disclaimer-section {
        font-size: 1.1rem;
    }

    .project-details-contact-us{
        height: auto;
    }

    .modal-content {
        width: 90%;
        max-height: 70%; /* Adjust the height for smaller screens */
    }

    .disclaimer-button {
        width: 25%; /* Adjust button width for smaller screens */
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 767px) {

    .project-details {
        margin-top: 115px;
    }

    .project-main-image {
        height: 35vh;
    }

    .enquire-button {
        bottom: 2rem;
        padding: 0.5rem 2rem;
        font-size: 1rem;
    }

    .project-info {
        width: 90%;
        height: auto;
        flex-direction: column;
    }

    .label-project-name-rera {
        font-size: 0.8rem;
    }
    
    .info-project-name-rera {
        font-size: 1.2rem;
    }

    .row {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 2vh;
    }

    .project-name {
        font-size: 2rem;
    }

    .project-description {
        font-size: 1rem;
    }

    .info-block {
        flex: 1;
        text-align: left;
        margin-bottom: 1rem;
    }

    .label {
        font-size: 0.8rem;
    }

    .info {
        font-size: 1.2rem;
    }

    .image-slider {
        width: 100%;
        height: auto;
    }

    .slider-card {
        width: 100%;
    }

    .slider-icon {
        font-size: 3rem;
    }

    .image-text {
        font-size: 1.5rem;
    }

    .image-description {
        font-size: 1rem;
    }

    .amenities-list {
        width: 90%;
    }

    .amenities-title {
        font-size: 2.5rem;
    }

    .amenity-row {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center align the amenity row */
        text-align: center; /* Center align text within the row */
    }

    .amenity-header {
        width: 100%; /* Ensure the header takes up full width */
        display: flex;
        justify-content: center; /* Center align the header content */
    }

    .amenity-text {
        font-size: 1rem;
    }

    .amenity-description {
        font-size: 0.8rem;
        text-align: center;
    }

    .floor-plans-section {
        margin-bottom: 15vh;
    }

    .floor-plans-title {
        font-size: 2.5rem;
    }

    .contact-us-section-project {
        height: auto;
    }

    .image-indicator {
        position: static;
        transform: none;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .floor-plans-section .slider-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .floor-plans-section .slider-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .floor-plans-section .image-indicator {
        position: static;
        transform: none;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
    }

    .floor-plans-section .image-text,
    .floor-plans-section .image-description {
        text-align: center;
        align-self: flex-start;
    }

    .location-section {
        flex-direction: column;
        align-items: center; /* Center align the location section */
        text-align: center; /* Center align text within the section */
    }

    .location-left, .location-right {
        width: 100%;
        padding: 1rem;
        justify-content: center;
        align-items: center;
    }

    .location-title {
        font-size: 2rem;
    }

    .location-headings {
        font-size: 1rem;
    }

    .location-name, .time-to-location {
        font-size: 1rem;
    }

    .location-map {
        height: 40vh;
    }

    .disclaimer-section {
        font-size: 1.1rem;
    }

    .modal-content {
        width: 90%;
        max-height: 70%; /* Adjust the height for smaller screens */
    }

    .disclaimer-button {
        width: 35%; /* Adjust button width for smaller screens */
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 480px) {
    .project-details {
        margin-top: 110px;
    }

    .project-main-image {
        height: 22vh; /* Adjust the height calculation */
    }

    .enquire-button {
        bottom: 2rem;
        padding: 0.5rem 2rem;
        font-size: 1rem;
    }

    .label-project-name-rera {
        font-size: 0.8rem;
    }
    
    .info-project-name-rera {
        font-size: 1.2rem;
    }

    .project-info {
        width: 90%;
        height: auto;
        flex-direction: column;
    }

    .row {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 4vh;
    }

    .project-name {
        font-size: 2rem;
    }

    .project-description {
        font-size: 1rem;
        text-align: justify;
    }

    .info-block {
        flex: 1;
        text-align: left;
        margin-bottom: 1rem;
    }

    .label {
        font-size: 0.8rem;
    }

    .info {
        font-size: 1.2rem;
    }

    .image-slider {
        width: 100%;
        height: 35vh;
    }

    .slider-card {
        width: 100%;
    }

    .slider-icon {
        font-size: 3rem;
    }

    .image-text {
        font-size: 1.5rem;
    }

    .image-description {
        font-size: 1rem;
    }

    .amenities-list {
        width: 90%;
    }

    .amenities-title {
        font-size: 2rem;
    }

    .amenity-row {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center align the amenity row */
        text-align: center; /* Center align text within the row */
    }

    .amenity-header {
        width: 100%; /* Ensure the header takes up full width */
        display: flex;
        justify-content: center; /* Center align the header content */
    }

    .amenity-text {
        font-size: 1rem;
    }

    .amenity-description {
        font-size: 0.8rem;
        text-align: center;
    }

    .floor-plans-section {
        margin-bottom: 1vh;
    }

    .floor-plans-title {
        font-size: 2rem;
    }

    .contact-us-section-project {
        height: auto;
    }

    .image-indicator {
        position: static;
        transform: none;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .floor-plans-section .slider-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .floor-plans-section .slider-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .floor-plans-section .image-indicator {
        position: static;
        transform: none;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        z-index: 10; /* Ensure the indicator is on top */
    }

    .floor-plans-section .image-text,
    .floor-plans-section .image-description {
        text-align: center;
        align-self: flex-start;
    }

    .location-section {
        flex-direction: column;
    }

    .location-left {
        width: 100%;
        padding: 1rem;
    }

    .location-right {
        width: 100%;
        padding: 1rem;
        justify-content: center;
        align-items: center;
    }

    .location-title {
        font-size: 2rem;
    }

    .location-headings {
        font-size: 1rem;
    }

    .location-name, .time-to-location {
        font-size: 1rem;
    }

    .location-map {
        height: 40vh;
    }

    .disclaimer-section {
        font-size: 0.8rem;
    }

    .modal-content {
        width: 90%;
        max-height: 70%; /* Adjust the height for smaller screens */
    }

    .disclaimer-button {
        width: 50%; /* Adjust button width for smaller screens */
        padding: 0.5rem 1rem;
        font-size: 1rem;
    }
}