.education-hosp {
    display: flex;
    height: 50vh;
    width: 100%;
}

.left-div, .right-div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative; /* Required for positioning the h1 */
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire div */
    opacity: 0.5;;
    z-index: 0; /* Ensure the image is behind the h1 */
}

.no-decoration {
    text-decoration: none; /* Remove default text decoration */
}

.left-div h1, .right-div h1 {
    font-family: 'Poppins-Light', sans-serif;
    color: #F4E282;
    font-size: 2rem;
    font-weight: normal;
    cursor: pointer; /* Change cursor to pointer on hover */
    position: relative; /* Position the h1 on top of the image */
    z-index: 1; /* Ensure the h1 is above the image */
}

.left-div h1::after, .right-div h1::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px; /* Adjust as needed */
    width: 0; /* Initially, the line is not visible */
    height: 2px; /* Adjust as needed */
    background-color: #F4E282; /* Line color */
    transition: width 0.3s ease; /* Smooth transition for the width */
}

.left-div h1:hover::after, .right-div h1:hover::after {
    width: 100%; /* On hover, the line expands to full width */
}

@media only screen and (max-width: 1024px) {
    .education-hosp {
        height: 35vh;
    }

    .left-div h1, .right-div h1 {
        font-size: 1.8rem;
    }
}

@media only screen and (max-width: 767px) {
    .education-hosp {
        flex-direction: column;
        height: 55vh;
    }

    .left-div, .right-div {
        width: 100%;
    }

    .left-div h1, .right-div h1 {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 480px) {
    .education-hosp {
        flex-direction: column;
    }

    .left-div, .right-div {
        width: 100%;
    }

    .left-div h1, .right-div h1 {
        font-size: 1.5rem;
    }
}